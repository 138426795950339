<template>
  <t-tooltip :position="position" :multiline="size">
    <template #html>
      <span v-html="label" />
    </template>
    <t-icon :icon="icon" />
  </t-tooltip>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },

    position: {
      type: String,
      required: false,
      default: 'top',
    },

    size: {
      type: String,
      required: false,
      default: 'medium',
    },

    icon: {
      type: [String, Array],
      required: false,
      default: () => {
        return ['far', 'question-circle'];
      },
    },
  },
};
</script>
