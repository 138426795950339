<template>
  <div class="t-form-card" :class="{ 't-form-card--coming-soon': comingSoon }">
    <t-card>
      <div v-if="$slots.header" class="t-form-card__header">
        <slot name="header" />
      </div>
      <div class="t-form-card__inner">
        <slot />
      </div>
    </t-card>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    comingSoon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>
<style lang="scss">
.t-form-card {
  $self: &;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  &__header {
    margin-bottom: $s-8;
  }

  &--coming-soon {
    #{$self}__inner {
      padding: $s-16;
      text-align: center;
      margin-top: $s-8;
    }
  }
}
</style>
