<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.organization.details.heading') }}
    </h3>
    <t-form-card>
      <template #header>
        <h4>
          {{ $t('pages.organization.details.subheading') }}
        </h4>
      </template>
      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(submit)">
          <t-grid>
            <t-grid-row>
              <t-grid-cell :size="12">
                <t-validation-wrapper
                  vid="name"
                  :name="$t('fields.name_with_legal_form')"
                  rules="required|min:3|max:255"
                >
                  <t-input v-model="form.name" type="text" :placeholder="$t('fields.name')" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell :size="12">
                <t-validation-wrapper
                  vid="usage_type"
                  :name="$t('fields.usage_type')"
                  rules="required"
                >
                  <OrganizationUsageTypeHelp slot="help" />
                  <UsageTypeSelect v-model="form.usage_type" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <h3>
                  {{ $t('fields.address.address') }}
                  <span
                    class="text--grey"
                    style="font-size: 1rem"
                  >{{ $t('pages.organization.address.hint') }}</span>
                </h3>
                <Address v-model="form.address" />
              </t-grid-cell>
            </t-grid-row>
          </t-grid>

          <t-form-element>
            <t-button-loading :loading="loading" native-type="submit" :disabled="invalid">
              {{ $t('global.actions.save') }}
            </t-button-loading>
          </t-form-element>
        </t-form>
      </validation-observer>
    </t-form-card>
  </t-page>
</template>
<script>
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import TFormCard from '~/components/TFormCard.vue';
import Address from '~/components/Fields/Address';
import UsageTypeSelect from '~/components/EnumSelect/UsageTypeSelect';
import Organization from '~/models/Organization';
import OrganizationUsageTypeHelp from '~/components/Help/OrganizationUsageTypeHelp';

export default {

  components: {
    TFormCard,
    TValidationWrapper,
    // eslint-disable-next-line vue/no-reserved-component-names
    Address,
    UsageTypeSelect,
    OrganizationUsageTypeHelp,
  },

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: null,
      loading: false,
    };
  },

  created() {
    this.form = this.getForm(this.organization);
  },

  methods: {
    getForm(organization) {
      return {
        name: organization.name,
        usage_type: organization.usage_type,
        address: {
          line_1: organization.address.line_1,
          line_2: organization.address.line_2,
          postcode: organization.address.postcode,
          city: organization.address.city,
          country: organization.address.country,
        },
      };
    },

    async submit() {
      this.loading = true;
      try {
        const newOrganization = await new Organization({
          id: this.organization.id,
          ...this.getPayload(),
        }).patch();

        this.$emit('updated', newOrganization);
        await this.$notify.success(this.$t('notifications.organization.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    getPayload() {
      return { ...this.form };
    },
  },
};
</script>
