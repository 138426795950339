<template>
  <organization-detail :organization="organization" @updated="updated" />
</template>

<script>
import OrganizationDetail from '~/pages/shared/organizations/OrganizationDetail';

export default {

  components: {
    OrganizationDetail,
  },

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  methods: {
    updated(organization) {
      this.$emit('updated', organization);
    },
  },
};
</script>
