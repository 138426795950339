<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Werden Sie eher Bewerber:innen in den Ausbildungsradar empfehlen oder suchen Sie nach Bewerber:innen?',
    },
  },
};
</script>
